export default {
  localhost: {
    url: 'http://localhost/request-developer-api/public/',
    upload_path: 'http://localhost/request-developer-api/upload/',
    upload_path_preview: 'https://s3-jkt1.dcloud.co.id/developer-request/',
    // upload_path: 'https://development.netciti.co.id/assets/upload/',
    // upload_path_preview: 'https://development.netciti.co.id/assets/upload/',
    // upload_path: 'https://s3-jkt1.dcloud.co.id/efab/',
    upload_path_temp: 'http://localhost/request-developer-api/upload_temp/',
    download_assets_preview: 'http://localhost/request-developer-api/public/download/download-preview',
    download_assets_url: 'http://localhost/request-developer-api/public/download/download-assets',
  },
  server: {
    // url: 'http://103.19.108.183/request-developer-api/public/',
    // upload_path: 'http://103.19.108.183/assets/upload/',
    // upload_path_temp: 'http://103.19.108.183/assets/upload_temp/',
    url: 'https://development.netciti.co.id/request-developer-api/public/',
    // upload_path: 'https://apiefab.netciti.co.id/assets/upload/',
    // upload_path: 'https://development.netciti.co.id/assets/upload/',
    // upload_path_preview: 'https://development.netciti.co.id/assets/upload/',
    upload_path: 'https://s3-jkt1.dcloud.co.id/developer-request/',
    upload_path_preview: 'https://s3-jkt1.dcloud.co.id/developer-request/',
    download_assets_preview: 'https://development.netciti.co.id/request-developer-api/public/download/download-preview',
    download_assets_url: 'https://development.netciti.co.id/request-developer-api/public/download/download-assets',
  },

}

import Vue from 'vue'
import BootstrapVue, { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

// Global Components
import './global-components'
import '@axios'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/sweet-alerts'
import '@/libs/toastification'
import 'vue-search-select/dist/VueSearchSelect.css'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
// import 'vue-kanban/src/assets/kanban.scss'

import { ModelSelect } from 'vue-search-select'

// signature
import VueSignaturePad from 'vue-signature-pad'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUsers, faCalendar, faCheckCircle, faCakeCandles,
  fas, fa,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import vueKanban from 'vue-kanban'
import App from './App.vue'
import store from './store'
import router from './router'

library.add(fas, faCakeCandles)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueSignaturePad)
Vue.component('ModelSelect', ModelSelect)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)

Vue.use(vueKanban)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')
require('@core/assets/fonts/feather/iconfont.css')
require('@core/scss/vue/libs/vue-flatpicker.scss')
require('vue-kanban/src/assets/kanban.scss')

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

import Vue from 'vue'
import VueRouter from 'vue-router'
import _accessApi from '@/router/_accessApi.js'
import axios from 'axios'

const Login = () => import('@/views/Login.vue')
const Dashboard = () => import('@/views/dashboard/Dashboard.vue')
const DashboardDev = () => import('@/views/dashboard/DashboardDev.vue')

// change Change Request & Memo
const Request = () => import('@/views/request/Request.vue')
const ChangeRequest = () => import('@/views/memo/Memo.vue')

// Master Area
const MasterApplications = () => import('@/views/master/Applications.vue')
const MasterPriorityStatus = () => import('@/views/master/PriorityStatus.vue')
const MasterRequestStatus = () => import('@/views/master/RequestStatus.vue')
const MasterMenu = () => import('@/views/master/Menu.vue')
const MasterAccessMenu = () => import('@/views/master/AccessMenu.vue')

function configRoutes() {
  return [
    { path: '*', redirect: 'error-404' },
    {
      path: '/',
      redirect: { name: 'dashboard' },
    },
    // {
    //   path: '/error-404',
    //   name: 'error-404',
    //   component: Page404,
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    {
      path: '/login',
      name: 'login',
      meta: {
        layout: 'full',
      },
      component: Login,
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
    },
    {
      path: '/dashboard-dev',
      name: 'dashboard-dev',
      component: DashboardDev,
    },
    {
      path: '/bugs',
      name: 'bugs',
      component: ChangeRequest,
    },
    {
      path: '/memo',
      name: 'memo',
      component: ChangeRequest,
    },
    {
      path: '/change-request',
      name: 'change-request',
      component: ChangeRequest,
    },
    {
      path: '/change-request/detail/:code',
      name: 'request-detail',
      component: Request,
    },

    {
      path: '/master',
      name: 'master',
      redirect: '/master/applications',
      component: {
        render(c) {
          return c('router-view')
        },
      },
      children: [
        {
          path: 'applications',
          name: 'master-applications',
          component: MasterApplications,
          meta: {
            pageTitle: 'Applications',
            breadcrumb: [
              {
                text: 'Master',
                active: false,
                to: '/master',
              },
              {
                text: 'Applications',
                active: true,
              },
            ],
          },
        },
        {
          path: 'priority-status',
          name: 'master-prioritystatus',
          component: MasterPriorityStatus,
          meta: {
            pageTitle: 'Priority Status',
            breadcrumb: [
              {
                text: 'Master',
                active: false,
                to: '/master',
              },
              {
                text: 'Priority Status',
                active: true,
              },
            ],
          },
        },
        {
          path: 'request-status',
          name: 'master-requeststatus',
          component: MasterRequestStatus,
          meta: {
            pageTitle: 'Request Status',
            breadcrumb: [
              {
                text: 'Master',
                active: false,
                to: '/master',
              },
              {
                text: 'Request Status',
                active: true,
              },
            ],
          },
        },
        {
          path: 'menu',
          name: 'master-menu',
          component: MasterMenu,
          meta: {
            pageTitle: 'Menu',
            breadcrumb: [
              {
                text: 'Master',
                active: false,
                to: '/master',
              },
              {
                text: 'Menu',
                active: true,
              },
            ],
          },
        },
        {
          path: 'access-menu',
          name: 'master-accessmenu',
          component: MasterAccessMenu,
          meta: {
            pageTitle: 'Menu',
            breadcrumb: [
              {
                text: 'Master',
                active: false,
                to: '/master',
              },
              {
                text: 'Access Menu',
                active: true,
              },
            ],
          },
        },
      ],
    },
  ]
}
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: configRoutes(),
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen

if (location.host === 'localhost:8080' || location.host === 'localhost:8081') {
  // My localhost
  axios.defaults.baseURL = _accessApi.localhost.url
  axios.defaults.upload_path = _accessApi.localhost.upload_path
  axios.defaults.upload_path_preview = _accessApi.localhost.upload_path_preview
  axios.defaults.upload_path_temp = _accessApi.localhost.upload_path_temp
  axios.defaults.download_assets_preview = _accessApi.localhost.download_assets_preview
  axios.defaults.download_assets_url = _accessApi.localhost.download_assets_url
} else {
  // server
  axios.defaults.baseURL = _accessApi.server.url
  axios.defaults.upload_path = _accessApi.server.upload_path
  axios.defaults.upload_path_temp = _accessApi.server.upload_path_temp
  axios.defaults.download_assets_preview = _accessApi.server.download_assets_preview
  axios.defaults.download_assets_url = _accessApi.server.download_assets_url
  axios.defaults.upload_path_preview = _accessApi.localhost.upload_path_preview
}

router.beforeEach((to, _, next) => {
  // useJwt.setToken(localStorage.getItem("token"));
  const token = localStorage.getItem('token')
  const publicPages = ['login', 'activation']
  const extract = to.path.split('/')
  const authRequired = !publicPages.includes(extract[1])

  if (authRequired && token === null) {
    alert('You not eligible to access this page!')
    next('/login')
  } else if (extract[1] === 'login' || extract[1] === 'activation') {
    next()
  } else {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    axios.post('/authentication/restricted', { path: to.path }).then(response => {
      next()
      // this function used if using ACCESS CONTROLL
      // if (response.data.is_allowed === true) {
      //   next()
      // } else {
      //   alert('You not eligible to access this page!')
      //   next('/login')
      // }
    }).catch(error => {
      alert('Your session has been expired')
      next('/login')
    })
  }
})

router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
